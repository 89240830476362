<template>
  <base-page class="privacy">
    <base-title :level="1">
      Privacy
    </base-title>
    <base-paragraph>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed iaculis tellus sed sollicitudin
      rutrum. Duis condimentum erat nec tincidunt luctus. In hac habitasse platea dictumst. Nam
      tempus, neque ultricies imperdiet vestibulum, risus felis consectetur justo, eu fringilla
      nulla mi a odio. Suspendisse volutpat odio vitae ligula porta, convallis commodo ipsum
      interdum. Nulla et quam sapien. Vestibulum ante ipsum primis in faucibus orci luctus et
      ultrices posuere cubilia curae; Curabitur suscipit maximus quam vel accumsan. Proin nec odio
      efficitur dolor imperdiet venenatis placerat sit amet nisl. Aliquam non pharetra nunc.
      Curabitur in nisl tristique, semper nulla nec, condimentum tortor.
    </base-paragraph>
    <base-paragraph>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed iaculis tellus sed sollicitudin
      rutrum. Duis condimentum erat nec tincidunt luctus. In hac habitasse platea dictumst. Nam
      tempus, neque ultricies imperdiet vestibulum, risus felis consectetur justo, eu fringilla
      nulla mi a odio. Suspendisse volutpat odio vitae ligula porta, convallis commodo ipsum
      interdum. Nulla et quam sapien. Vestibulum ante ipsum primis in faucibus orci luctus et
      ultrices posuere cubilia curae; Curabitur suscipit maximus quam vel accumsan. Proin nec odio
      efficitur dolor imperdiet venenatis placerat sit amet nisl. Aliquam non pharetra nunc.
      Curabitur in nisl tristique, semper nulla nec, condimentum tortor.
    </base-paragraph>
    <base-paragraph>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed iaculis tellus sed sollicitudin
      rutrum. Duis condimentum erat nec tincidunt luctus. In hac habitasse platea dictumst. Nam
      tempus, neque ultricies imperdiet vestibulum, risus felis consectetur justo, eu fringilla
      nulla mi a odio. Suspendisse volutpat odio vitae ligula porta, convallis commodo ipsum
      interdum. Nulla et quam sapien. Vestibulum ante ipsum primis in faucibus orci luctus et
      ultrices posuere cubilia curae; Curabitur suscipit maximus quam vel accumsan. Proin nec odio
      efficitur dolor imperdiet venenatis placerat sit amet nisl. Aliquam non pharetra nunc.
      Curabitur in nisl tristique, semper nulla nec, condimentum tortor.
    </base-paragraph>
    <base-paragraph>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed iaculis tellus sed sollicitudin
      rutrum. Duis condimentum erat nec tincidunt luctus. In hac habitasse platea dictumst. Nam
      tempus, neque ultricies imperdiet vestibulum, risus felis consectetur justo, eu fringilla
      nulla mi a odio. Suspendisse volutpat odio vitae ligula porta, convallis commodo ipsum
      interdum. Nulla et quam sapien. Vestibulum ante ipsum primis in faucibus orci luctus et
      ultrices posuere cubilia curae; Curabitur suscipit maximus quam vel accumsan. Proin nec odio
      efficitur dolor imperdiet venenatis placerat sit amet nisl. Aliquam non pharetra nunc.
      Curabitur in nisl tristique, semper nulla nec, condimentum tortor.
    </base-paragraph>
  </base-page>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>

</style>
